<template>

  <div>

    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <orders-list-filter
      :status-filter.sync="statusFilter"
      :search-query.sync="searchQuery"
      :customer-filter.sync="customerFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('Show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('entries')}}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <crud-button :onClickRefresh="refetchData" :onClickAddNew="addOrder" />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        small
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Date -->
        <template #cell(orderDate)="data">
          {{ formatDateTime(data.item.orderDate) }}
        </template>

        <!-- Column: user -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <small class="text-muted">@{{ data.item.user.fullName }} ({{ data.item.user.userName }})</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(orderStatus)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              :variant="resolveStatusVariant(data.item.orderStatus)"
            >
              <feather-icon
                :icon="resolveStatusIcon(data.item.orderStatus)"
                size="18"
                class="mr-50 text-white"
              />
              <span class="align-text-top text-capitalize">{{ data.item.orderStatusLabel }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(subtotal)="data">
          {{ data.item.currencyUnit }}{{ data.item.subtotal }}
        </template>

        <template #cell(isDownloadedFiles)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="data.item.isDownloadedFiles"
              class="custom-control-success"
              name="check-button"
              disabled
              >
              {{ $t('Downloaded') }}
            </b-form-checkbox>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Update -->
          <feather-icon
            @click="$router.push({ name: 'manage-order-trait-save', params: { id: data.item.id } })"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />

          <!-- Remove -->
          <feather-icon
            @click="deleteOrder(data.item.id)"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText, formatDateTime } from '@core/utils/filter'

// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import OrdersListFilter from '../orders/OrdersListFilter.vue'
import useOrdersList from './useOrdersList'

export default {
  components: {
    OrdersListFilter,
    vSelect,
  },
  methods: {
    addOrder() {
      this.$router.push({ name: 'manage-order-create' });
    },
    deleteOrder(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('orderManagement/removeOrder', { id })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: this.$t(response.message) },
              })
              this.refetchData()
            } else {
              AlertService.error(this, this.$t(response.message))
            }
          });
      });
    },
  },
  setup() {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
    
      resolveStatusVariant,
      resolveStatusIcon,
      refetchData,
    
      // Extra Filters
      statusFilter,
      customerFilter,
    } = useOrdersList(true)

    return {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderListTable,

      avatarText,
      formatDateTime,
    
      resolveStatusVariant,
      resolveStatusIcon,
      refetchData,
    
      // Extra Filters
      statusFilter,
      customerFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
